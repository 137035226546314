/* Container */
.User-Admin-Setup-Container {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Tab Navigation */
.User-Admin-Setup-Tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.User-Admin-Setup-Tab {
  padding: 10px 20px;
  border: none;
  background-color: #f5f5f5;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.User-Admin-Setup-Tab.active {
  background-color: #007bff;
  color: white;
}

.User-Admin-Setup-Tab:hover:not(.active) {
  background-color: #e0e0e0;
}

/* Content Section */
.User-Admin-Setup-Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* User/Admin Items */
.User-Admin-Setup-Item {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.User-Admin-Setup-Item:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}

/* User/Admin Icons */
.User-Admin-Setup-Icon {
  font-size: 24px;
  color: #007bff;
  margin-right: 15px;
}

.User-Admin-Setup-Icon.cashier {
  color: #f39c12; /* Yellow for cashiers */
}

.User-Admin-Setup-Icon.admin {
  color: #28a745; /* Green for admins */
}

/* User/Admin Details */
.User-Admin-Setup-Details {
  flex: 1;
}

.User-Admin-Setup-Details p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.User-Admin-Setup-Details strong {
  color: #333;
}

/* Revoke Button */
.User-Admin-Setup-RevokeBtn {
  background-color: #ff4d4d;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: auto; /* Prevent button from stretching */
  max-width: 150px; /* Optional: Limit width */
}

.User-Admin-Setup-RevokeBtn:hover {
  background-color: #ff1a1a;
  transform: scale(1.05);
}

/* Add User Button */
.User-Admin-Setup-AddUserBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: auto; /* Prevent button from stretching */
  max-width: 250px; /* Optional: Set a max-width for consistency */
}

.User-Admin-Setup-AddUserBtn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Add User Form */
.User-Admin-Setup-Form {
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.User-Admin-Setup-Form h3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.User-Admin-Setup-Form input,
.User-Admin-Setup-Form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.User-Admin-Setup-Form input:focus,
.User-Admin-Setup-Form select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0px 0px 4px rgba(0, 123, 255, 0.4);
}

.User-Admin-Setup-FormSubmitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: auto; /* Prevent button from stretching */
  max-width: 250px; /* Optional: Set a max-width */
}

.User-Admin-Setup-FormSubmitBtn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .User-Admin-Setup-Content {
    padding: 15px;
  }

  .User-Admin-Setup-Item {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .User-Admin-Setup-Icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .User-Admin-Setup-RevokeBtn,
  .User-Admin-Setup-AddUserBtn,
  .User-Admin-Setup-FormSubmitBtn {
    width: 100%;
    text-align: center;
  }
}
