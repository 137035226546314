.cart-container {
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cart-items {
    list-style: none;
    padding: 0;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .cart-item-image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 10px;
  }
  
  .cart-item-details {
    flex: 1;
  }
  
  .cart-item-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; /* Space between the buttons */
    justify-content: flex-end;
  }
  
  
  .suggestions-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .suggestions-container h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .suggestion-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Default for smaller screens */
    gap: 15px;
    padding: 0 10px;
  }
  
  .suggestion-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .suggestion-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .suggestion-image {
    width: 100%;
    height: auto;
    max-width: 120px;
    max-height: 120px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .suggestion-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .suggestion-details h4 {
    font-size: 1rem;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .suggestion-details p {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .add-suggestion-btn {
    padding: 6px 12px;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .add-suggestion-btn:hover {
    background-color: #218838;
    transform: scale(1.05);
  }
  
  /* Responsive layout for larger screens */
  @media (min-width: 768px) {
    .suggestion-grid {
      grid-template-columns: repeat(4, 1fr); /* 4 columns for larger screens */
    }
  }
  
  .cart-footer {
    position: sticky;
    bottom: 60px; /* Adjust to stay above mobile nav */
    background-color: white;
    padding: 10px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }
  
  .empty-cart-btn {
    background-color: #d9534f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .empty-cart-btn:hover {
    background-color: #c9302c;
  }

  .cart-item-actions .cart-btn {
    background-color: #d9534f; /* Red by default */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cart-item-actions .cart-btn:hover {
    background-color: #c9302c; /* Darker red on hover */
  }
  
  .cart-item-actions .cart-btn.green {
    background-color: #5cb85c; /* Green for add */
  }
  
  .cart-item-actions .cart-btn.green:hover {
    background-color: #4cae4c; /* Darker green on hover */
  }
  
  .checkout-btn {
    background-color: #5cb85c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .checkout-btn:hover {
    background-color: #4cae4c;
  }
  
  .checkout-options {
    margin-top: 20px;
    text-align: center;
  }
  
  .checkout-options p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .checkout-btn,
  .account-btn {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .checkout-btn {
    background-color: #33a1ff;
    color: white;
  }
  
  .checkout-btn:hover {
    background-color: #0073e6;
  }
  
  .account-btn {
    background-color: #ff5733;
    color: white;
  }
  
  .account-btn:hover {
    background-color: #c13b1e;
  }

  .checkout-modal-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
  
  .modal-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .guest-btn {
    background-color: #33a1ff;
    color: white;
  }
  
  .guest-btn:hover {
    background-color: #0073e6;
  }
  
  .account-btn {
    background-color: #ff5733;
    color: white;
  }
  
  .account-btn:hover {
    background-color: #c13b1e;
  }
  
  .spinner {
  animation: spin 1s linear infinite;
  font-size: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
