/* Main Container */
.order-summary-section {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Sticky Behavior */
.sticky {
  position: sticky;
  top: 20px;
}

/* Title */
.order-summary-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Section Headers */
.summary-section h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #444;
}

/* Cart Items */
.cart-grid {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.cart-item {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.cart-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

/* Total Cost */
.total-cost {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
  margin-top: 10px;
}

/* Contact Details */
.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-icon {
  margin-right: 10px;
  color: #333;
}

.contact-text {
  font-size: 1rem;
  color: #333;
}

/* Terms and Conditions */
.terms-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.terms-checkbox {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.terms-label {
  font-size: 0.9rem;
  line-height: 1.5;
}

.terms-highlight {
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .order-summary-section {
    margin-bottom: 10px;
  }
}
