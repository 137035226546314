.UserHome-Container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.UserHome-Header {
  text-align: center;
  padding: 15px 0;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
  border-bottom: 1px solid #ddd;
}

.UserHome-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Info Card */
.UserHome-InfoCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 15px;
}

.UserHome-Icon {
  background-color: #007bff;
  color: white;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.UserHome-Info {
  flex: 1;
  text-align: left;
}

.UserHome-Info p {
  margin: 5px 0;
  font-size: 0.95rem;
}

/* Section Cards */
.UserHome-SectionCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.UserHome-SectionCard:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.UserHome-SectionIcon {
  font-size: 2rem;
  color: #ff5733;
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
}

.UserHome-SectionDetails h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.UserHome-SectionDetails p {
  font-size: 0.9rem;
  color: #555;
}

/* Floating Logout Button */
.UserHome-LogoutBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff5733;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.UserHome-LogoutBtn:hover {
  background-color: #c13b1e;
  transform: scale(1.1);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .UserHome-Grid {
    grid-template-columns: 1fr; /* One card per row */
  }
}
