/* Fullscreen Overlay */
.checkout-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dim background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Box */
  .checkout-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    max-width: 90%; /* Prevent overflow */
    width: auto; /* Adjust width dynamically */
    max-height: 90vh; /* Prevent it from exceeding screen height */
    overflow-y: auto; /* Scroll if content is long */
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Close Button */
  .checkout-modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .checkout-modal-close-btn:hover {
    transform: scale(1.1);
  }
  
  /* Modal Body */
  .checkout-modal-body {
    padding: 20px;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .checkout-modal-content {
      max-width: 95%; /* Adjust for smaller screens */
      padding: 15px;
    }
  }
  