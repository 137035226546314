.admin-activities {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.activities-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.activities-table th,
.activities-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.activities-table th {
  background-color: #f4f4f4;
}

.activities-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.activities-table tr:hover {
  background-color: #f1f1f1;
}

.activities-table td {
  word-wrap: break-word;
  max-width: 300px;
}

/* No Activities Message */
.no-activities {
  text-align: center;
  font-size: 18px;
  color: #999;
  margin-top: 20px;
}

/* Mobile List Styles */
.activities-list {
  display: none; /* Hide by default */
}

.activity-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.activity-card h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.activity-card p {
  font-size: 14px;
  margin: 5px 0;
  color: #555;
  word-wrap: break-word;
}

/* Responsive Design */
@media (max-width: 768px) {
  .activities-table {
    display: none; /* Hide table on smaller screens */
  }

  .activities-list {
    display: flex; /* Show mobile list */
    flex-direction: column;
    gap: 15px;
  }
}

@media (min-width: 769px) {
  .activities-list {
    display: none; /* Hide mobile list on larger screens */
  }
}
