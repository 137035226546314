.manage-products-container {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
  }
  
  .manage-products-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Products Table */
  .products-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .products-table th,
  .products-table td {
    text-align: left;
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 14px;
  }
  
  .products-table th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .products-table td {
    vertical-align: middle;
  }
  
  .product-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  /* Buttons */
  .availability-btn {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .availability-btn.available {
    background-color: #28a745;
    color: white;
  }
  
  .availability-btn.unavailable {
    background-color: #dc3545;
    color: white;
  }
  
  .delete-btn {
    margin-top: 10px;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: #ff4d4d;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-btn:hover {
    background-color: #ff1a1a;
  }
  
  .availability-btn:hover {
    filter: brightness(90%);
  }
  