.delivery-payment-contact-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  width: 100%;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;
}

section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center; /* Center the text */
}

h2 {
  font-size: 1.25rem;
  margin: 0;
  color: #333;
}

.description {
  font-size: 0.875rem;
  color: #666;
  text-align: center; /* Center the description */
  margin-bottom: 16px; /* Add spacing between description and grid */
}

/* Grid layout for selections */
.selection-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Responsive grid */
  justify-items: center;
}

.selection-option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #f9f9f9;
  width: 100%;
  max-width: 150px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}

.selection-option.selected {
  border-color: #333;
  background: #e0f7fa;
}

.selection-option:hover {
  border-color: #555;
  background: #f0f0f0;
}

.selection-icon {
  font-size: 24px;
  margin-bottom: 8px;
  color: #555;
}

.sticky-navigation {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 16px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
}

.nav-btn {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s;
}

.nav-btn.back-btn {
  background: #f5f5f5;
  color: #333;
}

.nav-btn.back-btn:hover {
  background: #e0e0e0;
}

.nav-btn.next-btn {
  background: #007bff;
  color: #fff;
}

.nav-btn.next-btn:hover {
  background: #0056b3;
}

@media (min-width: 768px) {
  .selection-grid {
    gap: 24px;
    justify-content: center;
  }

  .selection-option {
    max-width: 180px;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .selection-grid {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .sticky-navigation {
    padding: 12px;
  }
}
