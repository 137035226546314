/* Admin Settings Page Container */
.admin-settings-page-container {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto; /* Center content */
}

.settings-title {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Settings Grid */
.settings-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px;
}

@media screen and (max-width: 992px) {
  .settings-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media screen and (max-width: 768px) {
  .settings-grid {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}

/* Settings Card */
.settings-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.settings-card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px); /* Hover animation */
  background-color: #f9f9f9; /* Slight background change */
}

.settings-card-icon {
  color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e6f2ff;
}

.settings-card-icon svg {
  width: 30px;
  height: 30px;
}

.settings-card-content {
  text-align: center;
}

.settings-card-content h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.settings-card-content p {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0;
}

/* Modal */
.admin-settings-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 15px; /* Padding to prevent content from touching edges */
}

.admin-settings-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 1200px; /* Limit max width for better mobile appearance */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
  position: relative; /* For close button positioning */
}

.admin-settings-close-modal-btn {
  background: #007bff; /* Primary color */
  color: white;
  border: none;
  padding: 10px 20px; /* Fixed padding for a consistent size */
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  top: 15px;
  right: 15px; /* Positioned in the top-right */
  width: auto; /* Prevent button from growing */
}

/* Buttons Inside the Modal Body */
.admin-settings-modal-body button {
  background: #28a745; /* Green for confirmation actions */
  color: white;
  border: none;
  padding: 10px 20px; /* Fixed size */
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px; /* Spacing between buttons */
  transition: all 0.3s ease;
  width: auto; /* Prevent growing with modal width */
}

.admin-settings-modal-body button:hover {
  background: #218838; /* Darker green on hover */
  transform: scale(1.05); /* Hover scaling effect */
}

/* Mobile-Specific Buttons */
@media screen and (max-width: 768px) {
  .admin-settings-close-modal-btn {
    padding: 8px 18px; /* Adjust padding for smaller screens */
    font-size: 13px; /* Slightly smaller text */
    top: 10px;
    right: 10px;
  }

  .admin-settings-modal-body button {
    padding: 10px 18px; /* Consistent size for buttons */
    font-size: 14px;
    width: auto; /* Ensure no growth with modal width */
  }
}

.admin-settings-close-modal-btn:hover {
  background: #0056b3; /* Slightly darker blue on hover */
  transform: scale(1.05); /* Small scaling effect */
}

/* Modal Title */
.modal-title {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

/* Modal Body */
.admin-settings-modal-body {
  padding: 15px;
  font-size: 14px;
  line-height: 1.6;
  color: #555;
}

/* Mobile Responsive Modal */
@media screen and (max-width: 768px) {
  .admin-settings-modal-content {
    width: 100%; /* Take full width for better UX on small screens */
    padding: 20px;
    max-width: none;
    border-radius: 0; /* Remove rounded corners for a clean mobile look */
  }

  .admin-settings-close-modal-btn {
    font-size: 16px;
    padding: 12px 20px;
    top: 15px;
    right: 15px; /* Adjust button size and spacing */
  }

  .modal-title {
    font-size: 22px; /* Larger title for better visibility */
  }

  .admin-settings-modal-body {
    font-size: 16px; /* Increase font size for readability */
    padding: 20px;
  }
}
