/* Two-Factor Authentication Container */
.two-factor-auth-container {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
  margin: 0 auto;
}

/* Headings */
.two-factor-auth-container h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #222;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

/* Status Messages */
.status-message {
  font-size: 1.1rem;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
}

.status-message .not-secured {
  color: #e74c3c;
  font-weight: bold;
}

.status-message .secured {
  color: #2ecc71;
  font-weight: bold;
}

/* Error Message */
.error-message {
  background-color: #ff6b6b;
  color: #fff;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

/* Button Wrapper */
.setup-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

/* Buttons */
button {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.8rem 1.2rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

/* Button Colors */
.Two-setup-btn {
  background-color: #3498db;
  color: #fff;
}

.Two-setup-btn:hover {
  background-color: #2980b9;
}

.Two-verify-btn {
  background-color: #2ecc71;
  color: #fff;
}

.Two-verify-btn:hover {
  background-color: #27ae60;
}

.Two-remove-btn {
  background-color: #e74c3c;
  color: #fff;
}

.Two-remove-btn:hover {
  background-color: #c0392b;
}

/* QR Code Section */
.Two-setup-step img {
  width: 250px;
  height: 200px;
  margin: 1rem 0;
}

/* Input Fields */
input[type="text"] {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  margin: 1rem 0;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
}

input[type="text"]:focus {
  border-color: #3498db;
}

/* Loading Message */
.loading {
  font-size: 1rem;
  color: #3498db;
  margin: 1rem 0;
  animation: fadeIn 0.5s ease-in-out;
}

/* Center QR Code on all screens */
.Two-setup-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code {
  width: 250px;
  height: 250px;
  margin: 1rem auto;
  object-fit: contain;
}


/* Responsive Styling */
@media (min-width: 768px) {
  .setup-options {
    justify-content: space-between;
  }

  .Two-setup-btn {
    flex: 1;
    margin: 0;
    min-width: 48%;
  }
}

@media (max-width: 767px) {
  .Two-setup-btn {
    width: 100%;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
