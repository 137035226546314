.admin-categories {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.admin-categories__split {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.admin-categories__add,
.admin-categories__view {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 300px; /* Ensure minimum width */
}

.admin-categories__input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admin-categories__button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-categories__button:hover {
  background-color: #0056b3;
}

.admin-categories__icon-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}

.admin-categories__icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.admin-categories__icon--selected {
  border-color: #007bff;
}

.admin-categories__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.admin-categories__item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.admin-categories__icon-view {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .admin-categories__split {
    flex-direction: column; /* Stack the panels vertically */
    gap: 10px;
  }

  .admin-categories__add,
  .admin-categories__view {
    width: 100%; /* Ensure panels take full width */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  .admin-categories__icon-list {
    justify-content: center; /* Center icons on smaller screens */
  }

  .admin-categories__icon {
    width: 40px; /* Smaller icon size for mobile */
    height: 40px;
  }

  .admin-categories__icon-view {
    width: 40px; /* Adjusted size for consistency */
    height: 40px;
  }
}
