.floating-chat {
    position: fixed;
    bottom: 20px;
    width: 300px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
  }
  
  .chat-header {
    background: #3498db;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
  }
  
  .chat-actions button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 5px;
  }
  
  .chat-messages {
    padding: 10px;
    flex-grow: 1;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .chat-input button {
    margin-left: 10px;
    padding: 8px 12px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .user-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .user-item:hover {
    background: #f4f4f4;
  }
  
  .user-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  