/* Admin Products Container */
.admin-products-container {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}

/* Tabs Section */
.admin-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px; /* Adds spacing between buttons */
}

.admin-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Spacing between icon and text */
  padding: 12px 20px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #333;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.admin-tab.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.3);
}

.admin-tab:hover {
  background-color: #0056b3;
  color: white;
  border-color: #0056b3;
  transform: translateY(-2px);
}

.admin-tab svg {
  flex-shrink: 0; /* Prevents icon shrinking */
}

/* Tab Content Section */
.tab-content {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
