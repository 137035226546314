/* Section Styling */
.details-section {
  padding: 20px;
  width: 100%;
  max-width: 820px;
  margin: auto;
  box-sizing: border-box;
}

/* Heading and Subtitle */
.details-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.details-subtitle {
  text-align: center;
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 24px;
}

/* Form Grid */
.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 18px;
  width: 100%;
}

/* Form Input Styling */
.form-input {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 7px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-input:hover {
  border-color: #007bff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.form-icon {
  color: #333;
  flex-shrink: 0;
}

input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 1rem;
  color: #333;
}

input::placeholder {
  color: #aaa;
}

/* Responsive for Larger Screens */
@media (min-width: 768px) {
  .form-grid {
    grid-template-columns: repeat(2, 1fr); /* Two fields per row */
  }
}
