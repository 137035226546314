/* Floating Icon */
.tracking-widget-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ff5733;
    color: white;
    border-radius: 20%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: grab;
    z-index: 1000;
    transition: transform 0.3s ease;
  }
  
  .tracking-widget-icon:active {
    cursor: grabbing;
  }
  
  .tracking-widget-icon:hover {
    transform: scale(1.1);
  }
  
  
  /* Hint for Tracking */
  .tracking-hint {
    position: absolute;
    bottom: 80px;
    right: 5px;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    animation: fade-in-out 3s ease-in-out;
  }
  
  @keyframes fade-in-out {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  /* Modal Styles */
  .tracking-widget-modal {
    position: fixed;
    bottom: 80px;
    right: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 350px;
    padding: 20px;
    z-index: 1001;
  }
  
  .modal-header {
    position: relative; /* Set this for the child `.close-btn` to align within the header */
    display: flex;
    justify-content: center; /* Center-align the header text */
    align-items: center;
    margin-bottom: 15px;
  }
  
  .modal-header h3 {
    font-size: 1.2rem;
    margin: 0;
  }
  
  .widget-close-btn {
    position: absolute;
    bottom: 10px; /* Distance from the top edge */
    left: 10px; /* Distance from the right edge */
    background: none;
    border: none;
    color: #ff5733;
    cursor: pointer;
    padding: 5px;
    font-size: 1.2rem; /* Slightly increase size for better visibility */
  }
  
  
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .tracking-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
  
  .check-btn {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .check-btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .loader-icon {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Order Status Steps */
  .order-status-container {
    margin-top: 20px;
  }
  
  .order-status-steps {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .order-step {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    color: #555;
  }
  
  .order-step.active-step {
    color: #4caf50;
    font-weight: bold;
  }
  

  .track-again-btn {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  .track-again-btn:hover {
    background-color: #0056b3;
  }
  