.admin-review-container {
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px; /* Restrict max width for larger screens */
  margin: 0 auto; /* Center container */
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.review-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.review-table thead {
  background-color: #007bff;
  color: #fff;
  text-align: left;
}

.review-table th,
.review-table td {
  padding: 10px 15px;
  border: 1px solid #ddd;
  text-align: center;
}

.review-table tbody tr:hover {
  background-color: #f1f1f1;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.delete-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.delete-btn:hover {
  background-color: #a71d2a;
}

/* Responsive Styles */
@media (max-width: 768px) {
  h2 {
    font-size: 1.2rem; /* Smaller title font size */
  }

  .review-table {
    display: block; /* Make table scrollable on small screens */
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    border: none;
  }

  .review-table thead {
    display: none; /* Hide table headers */
  }

  .review-table tbody tr {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    margin-bottom: 10px;
    padding: 15px;
    width: 150%; /* Take up full width on mobile */
  }

  .review-table td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    border: none;
    font-size: 0.9rem;
    width: 100%; /* Ensure full width for each item */
  }

  .review-table td::before {
    content: attr(data-label); /* Add labels for accessibility */
    font-weight: bold;
    color: #007bff;
    flex: 1;
    margin-right: 10px;
  }

  .product-image {
    width: 60px; /* Larger images for better visibility on mobile */
    height: 60px;
  }

  .delete-btn {
    padding: 8px 12px;
    font-size: 1rem;
    align-self: flex-end; /* Align delete button at the bottom */
  }
}
