/* Loader Container */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* High z-index to overlay everything */
}

/* Blurred Background */
.loader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../Assets/Hero.jpg'); /* Hero image as background */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px); /* Blur effect applied to the background */
  z-index: -1; /* Push background behind the spinner */
  opacity: 0.8; /* Optional dimming effect */
}

/* Spinner Styles */
.spinner {
  width: 80px; /* Size of the spinner */
  height: 80px;
  border: 10px solid rgba(255, 255, 255, 0.2); /* Transparent light outer border */
  border-top: 10px solid white; /* White color for spinner top */
  border-radius: 50%; /* Circle shape */
  animation: spin 1.5s linear infinite; /* Smooth rotation animation */
  z-index: 1; /* Keep spinner above the blurred background */
}

/* Spinner Animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
