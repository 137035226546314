/* WelcomeLoader Styles */
.welcome-loader {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh; /* Full-screen height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* Center the spinner horizontally */
  background-image: url('../Assets/Hero.jpg'); /* Use the image as the background */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Spinner Styles */
.spinner {
  width: 120px; /* Larger spinner */
  height: 100px;
  border: 10px solid rgba(255, 255, 255, 0.2); /* Light outer border */
  border-top: 10px solid #ff5733; /* Primary theme color for spinner */
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  margin-top: auto; /* Place spinner in the center of the screen */
  margin-bottom: auto; /* Place spinner in the center of the screen */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Developer Footer Styles */
footer {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white;
  text-align: center;
  padding: 20px 0; /* Larger padding for a bigger footer */
  font-size: 1rem; /* Slightly larger font size */
}


/* Version Text */
.app-version {
  font-size: 0.9rem;
  color: white; /* Match the theme */
  opacity: 0.8; /* Slightly transparent */
  margin-bottom: 10px; /* Space above the footer */
}

