/* General Admin Status Container */
.admin-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
  font-family: 'Arial', sans-serif;
}

/* Verification Box Styling */
.verification-box {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  max-width: 400px;
  width: 90%;
  text-align: center;
  animation: fadeIn 0.5s ease;
}

/* Header and Icon */
.verification-box h1 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.verification-box .info-message {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

/* Input Container */
.verification-input {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.verification-input:focus-within {
  border-color: #007bff;
}

.verification-input input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem;
  color: #333;
}

.verification-input input::placeholder {
  color: #aaa;
}

.verification-input svg {
  margin-right: 0.5rem;
  color: #007bff;
}

/* Error Message Styling */
.error-message {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Verify Button Styling */
.verify-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.verify-btn:hover {
  background-color: #0056b3;
}

.verify-btn:active {
  transform: scale(0.98);
}

.verify-btn:disabled {
  background-color: #b3d7ff;
  cursor: not-allowed;
}

.loading-icon {
  margin-right: 0.5rem;
  animation: spin 1s linear infinite;
}

/* Keyframes for Loading Icon */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .verification-box {
    padding: 1.5rem;
  }

  .verification-box h1 {
    font-size: 1.5rem;
  }

  .verification-box .info-message {
    font-size: 0.9rem;
  }

  .verify-btn {
    width: 100%;
  }
}
