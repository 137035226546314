/* Header styles */
.header {
  position: sticky;
  top: 0;
  background: transparent;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  max-width: 1200px; /* Ensures the header does not exceed this width */
  margin: 0 auto; /* Centers the header within the viewport */
  width: 100%; /* Ensures it stretches to fit smaller screens */
}

/* Optional: A container to wrap content */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px; /* Adds horizontal padding for spacing */
}


/* Desktop Navigation */
.desktop-nav {
  display: flex;
  gap: 20px;
}

/* Navigation Links */
.nav-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background: #f9f9f9;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.nav-link:hover {
  transform: translateY(-5px) scale(1.1);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.nav-link:active {
  transform: translateY(-2px);
}

/* Hover Background Effect */
.nav-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  background: rgba(255, 87, 51, 0.2); /* Slight hover background */
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.nav-link:hover::before {
  opacity: 1;
}

/* Navigation Icon */
.nav-icon {
  font-size: 18px;
}

/* Cart Count Badge */
.cart-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #dc3545;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 2px 6px;
}

/* Cart Vibration Animation */
@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
}

.vibrating {
  animation: vibrate 0.3s ease-in-out 2; /* Vibrates twice */
}


/* Active Navigation Styling - Change only text and icon color */
.nav-link.active-nav {
  color: #ff5733 !important; /* Make text red */
}

.nav-link.active-nav .nav-icon {
  color: #ff5733 !important; /* Make icon red */
}
