.best-sellers {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  max-width: 1000px;
  margin: 20px auto;
}

.best-sellers h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 22px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.best-sellers table {
  width: 100%;
  border-collapse: collapse;
}

.best-sellers thead th {
  background-color: #f5f5f5;
  color: #333;
  font-weight: bold;
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #ddd;
}

.best-sellers tbody tr {
  border-bottom: 1px solid #ddd;
}

.best-sellers tbody tr:hover {
  background-color: #f9f9f9;
}

.best-sellers tbody td {
  padding: 12px;
  color: #555;
  text-align: left;
}

.best-sellers tbody td:first-child {
  font-weight: bold;
  color: #333;
}

.best-sellers tbody td:last-child {
  text-align: center;
}

.best-sellers tbody tr td[colspan="3"] {
  color: #999;
}

/* Responsive Design */
@media (max-width: 768px) {
  .best-sellers {
    padding: 10px;
  }

  .best-sellers h2 {
    font-size: 18px;
  }

  .best-sellers table {
    display: none; /* Hide the table for mobile screens */
  }

  /* Card-based layout for mobile */
  .best-sellers .mobile-list {
    display: block;
  }

  .best-sellers .mobile-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .best-sellers .mobile-card h3 {
    font-size: 16px;
    margin: 0;
    color: #333;
  }

  .best-sellers .mobile-card p {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .best-sellers h2 {
    font-size: 16px;
  }

  .best-sellers .mobile-card h3 {
    font-size: 14px;
  }

  .best-sellers .mobile-card p {
    font-size: 12px;
  }
}
