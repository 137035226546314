.receipt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.receipt-content {
  max-width: 600px;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.receipt-box {
  padding: 20px;
  border-radius: 10px;
  background: #fdfdfd;
  border: 2px solid #ddd;
}

.receipt-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.receipt-details {
  font-size: 1rem;
  margin-top: 15px;
  text-align: left;
}

.success-icon {
  color: #4caf50;
  margin-bottom: 10px;
}

.error-icon {
  color: #f44336;
  margin-bottom: 10px;
}

.tracking-number {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
}

.tracking-number:hover {
  text-decoration: underline;
}

.retry-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.retry-button:hover {
  background-color: #d32f2f;
}
