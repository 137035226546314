/* Home Styles */
.home-container {
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;
  text-align: center;
  background-color: #f8f9fa;
  padding: 0px;
}

.hero-section {
  position: relative;
  background-image: url('../Assets/hero2.png');
  background-size: cover;
  background-position: center;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
}

.home-brand-name {
  position: absolute;
  top: 10%;
  font-size: 3rem;
  font-weight: bold;
  color: #faf5f5;
}

.hero-content {
  position: relative;
}

.hero-image {
  width: 200px;
  height: auto;
}

.tagline {
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: 400;
}

.order-now-btn {
  padding: 12px 24px;
  font-size: 1rem;
  color: white;
  background-color: #ff5733;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
  width: 90px;
}

.order-now-btn:hover {
  background-color: #c13b1e;
}

.featured-section {
  margin: 40px 0;
}

.featured-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.featured-items {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.featured-item {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.featured-item img {
  width: 100%;
  height: auto;
}

.featured-item p {
  margin: 10px 0;
  font-size: 1rem;
  font-weight: bold;
}

.cta-section {
  margin: 40px 0;
  text-align: center;
}

.cta-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.cta-section ul {
  list-style: none;
  padding: 0;
}

.cta-section li {
  font-size: 1.2rem;
  margin: 10px 0;
}
