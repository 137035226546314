/* Main Admin Header Styles */
.admin-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #2c3e50; /* Dark color for header */
  color: white;
  padding: 15px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger-menu {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.admin-nav {
  display: flex;
  gap: 15px;
  transition: transform 0.3s ease-in-out;
}

.admin-nav-link {
  text-decoration: none;
  color: #ecf0f1;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.admin-nav-link:hover {
  background-color: #34495e;
  color: #ecdbff;
}

.admin-active {
  background-color: #1abc9c; /* Active link color */
  color: white;
}

/* Logout Button */
.admin-logout-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.admin-logout-btn .spinner {
  border: 2px solid white;
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Styles for Smaller Screens */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block; /* Show hamburger menu */
  }

  .admin-nav {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #2c3e50;
    transform: translateY(-200%);
    transition: transform 0.3s ease-in-out;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .admin-nav.open {
    transform: translateY(0); /* Slide menu down */
  }

  .admin-nav-link {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #34495e;
  }

  .admin-logout-btn {
    width: 100%;
    margin-top: 10px;
  }
}

.admin-nav-link.restricted {
  color: #888;
  cursor: not-allowed;
}

.admin-nav-link.restricted:hover {
  background-color: transparent;
  color: #888;
}

.badge {
  background-color: red;
  color: white;
  border-radius: 30%;
  padding: 4px 8px;
  font-size: 12px;
  margin-left: 5px;
  vertical-align: top;
}

.loading-icon {
  margin-right: 0.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


