/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Container */
  .modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 1200px; /* Adjust for larger screens */
    position: relative;
    overflow-y: auto; /* Add scroll for content overflow */
    max-height: 90%; /* Prevent modal from exceeding viewport height */
  }
  
/* Close Button */
.modal-close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #ff5733;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px; /* Proper button sizing */
  font-size: 1rem;
  z-index: 1001; /* Ensure it stays above the modal content */
  width: auto; /* Prevent button from stretching */
  display: inline-block; /* Ensure proper button behavior */
}

.modal-close-btn:hover {
  background-color: #c13b1e;
}

  
  /* Grid Layout for Modal Content */
  .modal-container .menu-grid {
    display: grid;
    gap: 20px;
  }
  
  /* Large Screens */
  @media (min-width: 768px) {
    .modal-container .menu-grid {
      grid-template-columns: repeat(4, 1fr); /* 4 items per row */
    }
  }
  
  /* Small Screens */
  @media (max-width: 767px) {
    .modal-container .menu-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    }
  }
  