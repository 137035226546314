.UserSettings-Container {
  max-width: 600px;
  margin: auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.UserSettings-Container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
}

.UserSettings-Form {
  margin-bottom: 30px;
}

.UserSettings-FormGroup {
  margin-bottom: 20px;
}

.UserSettings-InputWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

.UserSettings-InputIcon {
  color: #888;
}

.UserSettings-InputWrapper input {
  border: none;
  outline: none;
  flex: 1;
  background-color: transparent;
  font-size: 1rem;
}

.UserSettings-SaveBtn {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.UserSettings-SaveBtn:hover {
  background-color: #0056b3;
}

.LoaderIcon {
  animation: spin 1s linear infinite;
  font-size: 1.2rem;
}

/* Spinner Animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
