/* General Styles */
  
  /* Admin Login Container */
  .admin-login-container {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    text-align: center;
    position: relative;
    left: 450px;
    top: 150px;
  }
  
  /* Header Styles */
  .admin-login-container h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  /* Input Group */
  .admin-input-group {
    margin-bottom: 20px;
    position: relative;
  }
  
  .admin-input-group input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }
  
  .admin-input-group input:focus {
    border-color: #ff6f61;
    box-shadow: 0 0 8px rgba(255, 111, 97, 0.2);
  }
  
  /* Submit Button */
  button[type="submit"] {
    background-color: #ff6f61;
    color: white;
    font-size: 1rem;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #e55e51;
    transform: translateY(-2px);
  }
  
  button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
  }
  
  /* Spinner (Loading) */
  .spinner {
    width: 18px;
    height: 18px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid white;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    margin-right: 8px;
    display: inline-block;
  }
  
  /* Keyframes for Spinner */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Footer for Extra Information */
  .admin-footer {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #777;
  }
  
  .admin-footer a {
    color: #ff6f61;
    text-decoration: none;
  }
  
  .admin-footer a:hover {
    text-decoration: underline;
  }
  
  /* Media Queries for Responsiveness */
  @media screen and (max-width: 480px) {
    .admin-login-container {
      padding: 20px;
    }
  
    .admin-login-container h2 {
      font-size: 1.5rem;
    }
  
    input {
      font-size: 0.9rem;
    }
  
    button[type="submit"] {
      font-size: 0.9rem;
    }
  }
  