/* General Container */
.database-backup-container {
  font-family: "Poppins", sans-serif;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.database-backup-container h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Manual Backup Button */
.manual-backup-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.manual-backup-btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.manual-backup-btn:hover {
  background-color: #0056b3;
}

.manual-backup-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Header Row */
.backup-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-weight: bold;
  font-size: 16px;
  color: #555;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 8px;
  margin-bottom: 10px;
  text-align: center;
}

/* Backup Rows */
.backup-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 14px;
  color: #333;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.backup-row span {
  overflow-wrap: break-word;
}

.backup-row:nth-child(even) {
  background-color: #f8f9fa;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.download-btn,
.restore-btn {
  padding: 8px 10px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-btn {
  background-color: #007bff;
}

.download-btn:hover {
  background-color: #0056b3;
}

.restore-btn {
  background-color: #dc3545;
}

.restore-btn:hover {
  background-color: #c82333;
}

/* No Backups Message */
.no-backups {
  text-align: center;
  font-size: 16px;
  color: #777;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .backup-header,
  .backup-row {
    grid-template-columns: 1fr 1fr; /* Adjust to 2 columns on medium screens */
    text-align: left;
    padding: 8px;
  }

  .backup-header span:nth-child(3),
  .backup-row span:nth-child(3) {
    display: none; /* Hide the "Backup File" column on smaller screens */
  }

  .backup-header span:nth-child(4),
  .backup-row span:nth-child(4) {
    grid-column: 1 / -1; /* Span across the full row */
    text-align: center;
  }

  .action-buttons {
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 480px) {
  .database-backup-container h2 {
    font-size: 18px;
  }

  .manual-backup-btn {
    font-size: 14px;
    padding: 8px 15px;
  }

  .backup-header,
  .backup-row {
    grid-template-columns: 1fr; /* Stacks all rows for very small screens */
    gap: 5px;
  }

  .backup-header span,
  .backup-row span {
    text-align: left;
    font-size: 12px;
  }

  .action-buttons {
    flex-direction: column;
  }


}
