/* Mobile navigation styles */
.mobile-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: #fff;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.nav-item {
  position: relative; /* Set relative positioning for proper absolute placement of count */
  text-align: center;
  font-size: 12px;
  color: #333;
  text-decoration: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, color 0.3s ease;
}

.cart-count {
  position: absolute; /* Position relative to the cart icon */
  top: -5px; /* Adjust vertical placement */
  right: 0; /* Align to the right of the icon */
  background: red; /* Background color for the count */
  color: white; /* Text color */
  border-radius: 20%; /* Make it circular */
  padding: 2px 9px; /* Adjust size */
  font-size: 10px; /* Smaller font size */
  font-weight: bold; /* Make text bold */
  line-height: 1; /* Adjust text alignment */
}

.nav-item:hover {
  transform: translateY(-3px);
}

.nav-icon {
  font-size: 20px;
  margin-bottom: 5px;
}

/* Icon-specific colors */
.menu-icon {
  color: #474646;
}

.cart-icon {
  color: #474646;
}

.favourite-icon {
  color: #474646;
}

.account-icon {
  color: #474646;
}

.review-icon {
  color: #474646;
}

/* Active link styling */
.nav-item.active {
  transform: scale(1.2); /* Zoom effect */
  color: #FF5733; /* Matches the Home icon's color */
}

.nav-item.active .menu-icon {
  color: #FF5733;
}

.cart-count-red {
  color: white; /* Inherit color for consistent appearance */
}


.nav-item.active .cart-icon {
  color: #33A1FF;
}

.nav-item.active .favourite-icon {
  color: #FF33A1;
}

.nav-item.active .account-icon {
  color: #33FF57;
}

.nav-item.active .review-icon {
  color: #FFC300;
}
