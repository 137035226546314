/* Main Voucher Page Container */
.voucher-page-container {
  font-family: 'Poppins', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

/* Header */
h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
  color: #333;
}

/* Informational Section */
.info-container {
  background: #007bff;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.info-container h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.info-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.info-container ul li {
  margin-bottom: 8px;
  font-size: 1rem;
}

/* Credit Balance Section */
.credit-balance-container {
  text-align: center;
  margin-bottom: 30px;
  background: #28a745;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
}

.credit-balance-container h3 {
  margin: 0;
  font-size: 1.5rem;
}

.credit-balance-container p {
  margin: 5px 0 0;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Vouchers Grid */
.vouchers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Voucher Card */
.voucher-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Voucher Icon */
.voucher-icon {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #007bff;
}

/* Voucher Details */
.voucher-details h4 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.voucher-details p {
  font-size: 1rem;
  margin: 0;
  color: #555;
}

/* Redeem Button */
.redeem-btn {
  background-color: #ffc107;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1rem;
}

.redeem-btn:hover {
  background-color: #e0a800;
}

/* Redeemed Text */
.redeemed-text {
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #888;
}
