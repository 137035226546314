.payment-gateway-container {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
}

.payment-gateway-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.payment-gateway-form-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.payment-gateway-form-row label {
  font-weight: bold;
  flex: 1;
  text-align: right;
}

.payment-gateway-form-row select {
  flex: 3;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Configuration Row */
.payment-gateway-config-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap; /* Ensures elements wrap on smaller screens */
}

.payment-gateway-config-row button,
.payment-gateway-config-row input {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
}

.payment-gateway-config-row input {
  flex: 2;
  border: 1px solid #ccc;
}

.payment-gateway-config-row input[disabled] {
  background-color: #f5f5f5;
  color: #888;
}

.payment-gateway-config-row .setup-btn {
  flex: 1;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.payment-gateway-config-row .setup-btn:hover {
  background-color: #0056b3;
}

.payment-gateway-config-row .save-btn {
  flex: 1;
  background-color: #28a745;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.payment-gateway-config-row .save-btn:hover {
  background-color: #218838;
}

/* Modal Styles */
.payment-gateway-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-gateway-modal-content {
  background: #fff;
  width: 90%;
  max-width: 800px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.payment-gateway-modal-content h3 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
}

.payment-gateway-modal-content ol {
  padding-left: 20px;
  line-height: 1.6;
  font-size: 16px;
}

.payment-gateway-close-modal-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #dc3545;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.payment-gateway-close-modal-btn:hover {
  background: #c82333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .payment-gateway-container {
    padding: 15px;
  }

  .payment-gateway-form-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .payment-gateway-form-row label {
    text-align: left;
    margin-bottom: 5px;
  }

  .payment-gateway-config-row {
    flex-direction: column;
    align-items: stretch;
  }

  .payment-gateway-config-row input,
  .payment-gateway-config-row button {
    width: 100%;
  }

  .payment-gateway-modal-content {
    padding: 20px;
    width: 95%;
  }

  .payment-gateway-modal-content h3 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .payment-gateway-container h2 {
    font-size: 18px;
  }

  .payment-gateway-form-row label,
  .payment-gateway-form-row select,
  .payment-gateway-config-row input,
  .payment-gateway-config-row button {
    font-size: 12px;
  }

  .payment-gateway-modal-content h3 {
    font-size: 16px;
  }

  .payment-gateway-modal-content ol {
    font-size: 14px;
  }
}
