/* Main Container */
.Home-Hero-Container {
  margin-top: 40px;
  padding: 30px;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Sticky Header */
.Home-Hero-StickyHeader {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Home-Hero-Icon {
  font-size: 2.5rem;
  color: #ff5733;
}

/* Filter Section */
.Home-Hero-FilterContainer {
  display: flex; /* Align the button and dropdown properly */
  flex-direction: column; /* Stack the button and dropdown */
  align-items: flex-start; /* Align content to the left */
  gap: 10px; /* Add space between button and dropdown */
}

.Home-Hero-FilterBtn {
  display: inline-block; /* Prevent the button from stretching */
  padding: 10px 15px; /* Adjust padding for proper button size */
  background-color: #33a1ff; /* Button color */
  color: white; /* Text color */
  border: none; /* Remove default borders */
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover effect */
  width: auto; /* Ensure the button width matches its content */
  text-align: center; /* Center align text */
}

.Home-Hero-FilterBtn:hover {
  background-color: #0073e6; /* Darker shade on hover */
}


/* Filter Dropdown */
.Home-Hero-FilterDropdown {
  display: flex; /* Arrange options in a row */
  flex-wrap: wrap; /* Wrap items if necessary */
  gap: 10px; /* Space between options */
}

.Home-Hero-FilterOption {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Home-Hero-CategoryIcon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%; /* Circle-shaped icons */
}

/* Category Header */
.Home-Hero-SectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.Home-Hero-CategoryHeaderIcon {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
}

/* See More Button */
.Home-Hero-SeeMoreBtn {
  padding: 8px 12px;
  background-color: #33a1ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  width: auto;
}

.Home-Hero-SeeMoreBtn:hover {
  background-color: #0073e6;
}

/* Product Grid */
.Home-Hero-MenuGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); /* Larger min size */
  gap: 25px; /* Increase gap for spacing */
}

/* Product Card */
.Home-Hero-MenuItem {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
  padding: 25px; /* More padding */
}

/* Product Image */
.Home-Hero-MenuImage {
  width: 120px; /* Larger width */
  height: 120px; /* Larger height */
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px; /* Add space between image and details */
}

/* Product Details */
.Home-Hero-MenuDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Home-Hero-MenuName {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Home-Hero-FavoriteIcon {
  font-size: 1.4rem;
  color: #ddd;
  cursor: pointer;
  transition: color 0.3s ease;
}

.Home-Hero-FavoriteIcon.favorited {
  color: #ff5733;
}

/* Price */
.Home-Hero-MenuPrice {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Action Area */
.Home-Hero-MenuActions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
}

.Home-Hero-MenuActions button {
  padding: 5px 10px;
  background-color: #ff5733;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Home-Hero-MenuActions button:hover {
  background-color: #c13b1e;
}

.Home-Hero-Quantity {
  font-size: 1.2rem;
  font-weight: bold;
}

/* Modal */
.Home-Hero-ModalContent {
  max-width: 800px;
  margin: auto;
}

.Home-Hero-CloseModalBtn {
  padding: 8px 12px;
  background-color: #ff5733;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto;
}

.Home-Hero-CloseModalBtn:hover {
  background-color: #c13b1e;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .Home-Hero-MenuGrid {
    grid-template-columns: 1fr; /* One product per row */
  }
}

/* Modal Close Button Styles */
.Modal-CloseButton {
  display: inline-block; /* Ensure the button only takes up the width of its content */
  padding: 8px 12px; /* Adjust padding for comfortable click area */
  background-color: #ff5733; /* Background color for visibility */
  color: white; /* Text color */
  border: none; /* Remove default button border */
  border-radius: 5px; /* Rounded corners */
  font-size: 0.9rem; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  width: auto; /* Ensure width is just enough for the content */
}

.Modal-CloseButton:hover {
  background-color: #c13b1e; /* Darker shade on hover */
}
