.admin-orders {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Table styles */
.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.orders-table th,
.orders-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.orders-table th {
  background-color: #f4f4f4;
}

/* Button styles */
.order-number-btn {
  background: none;
  color: #007bff;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}



/* Modal styles */
.order-modal {
  background: #fff;
  padding: 20px;
  max-width: 500px;
  margin: 40px auto;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.order-modal h2 {
  margin-bottom: 20px;
}

.order-modal ul {
  list-style: none;
  padding: 0;
}

.order-modal ul li {
  margin: 10px 0;
}

.close-modal-btn {
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.close-modal-btn:hover {
  background: #c82333;
}

/* Overlay styles */
.order-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .orders-table {
    border: 0;
  }

  .orders-table thead {
    display: none; /* Hide table headers for mobile */
  }

  .orders-table tr {
    display: block;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }

  .orders-table td {
    display: block;
    text-align: right;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    padding: 10px 5px;
    position: relative;
  }

  .orders-table td::before {
    content: attr(data-label); /* Use data-label attribute for mobile */
    float: left;
    font-weight: bold;
    color: #333;
  }

  .order-number-btn {
    display: inline-block;
    margin-top: 5px;
    font-size: 14px;
  }

  .close-modal-btn {
    width: 100%;
  }

  .order-modal {
    width: 90%;
    margin: 20px auto;
    padding: 15px;
  }

  .order-modal h2 {
    font-size: 18px;
  }
}

.no-orders-message {
  text-align: center;
  font-size: 1.2rem;
  color: #6c757d;
  margin-top: 20px;
}

.orders-table select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.order-number-btn {
  background-color: #c1ae33;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.no-orders-message {
  text-align: center;
  color: #888;
  margin-top: 20px;
}

.close-modal-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-modal-btn:hover {
  background-color: #c82333;
}
