.enhanced-account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    font-family: 'Poppins', sans-serif;
  }
  
  .enhanced-account-form-container {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .enhanced-form-heading {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .enhanced-account-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .enhanced-input-group {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .enhanced-input-icon {
    position: absolute;
    left: 10px;
    font-size: 1.2rem;
    color: #aaa;
  }
  
  .enhanced-input-group input {
    width: 100%;
    padding: 10px 40px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .enhanced-input-group input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .enhanced-password-toggle-icon {
    position: absolute;
    right: 10px;
    font-size: 1.2rem;
    color: #aaa;
    cursor: pointer;
  }
  
  .enhanced-submit-btn {
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .enhanced-submit-btn:hover {
    background-color: #0056b3;
  }
  
  .enhanced-forgot-password {
    text-align: center;
    color: #007bff;
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  .enhanced-forgot-password:hover {
    text-decoration: underline;
  }
  
  .enhanced-switch-action {
    text-align: center;
    font-size: 0.9rem;
  }
  
  .enhanced-switch-link {
    color: #007bff;
    cursor: pointer;
  }
  
  .enhanced-switch-link:hover {
    text-decoration: underline;
  }
  
  .enhanced-google-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    background-color: #db4437;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .enhanced-google-btn:hover {
    background-color: #c5362a;
  }
  