.add-product-container {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
  }
  
  .forms-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .single-product-form,
  .combo-product-form {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
  }
  
  .form-row {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  input,
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
/* Combo Products List */
.combo-products-list {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Spacing between items */
    margin-top: 10px;
  }
  
  /* Combo Product Item */
  .combo-product-item {
    display: flex;
    align-items: center; /* Align checkbox and text vertically */
    gap: 10px; /* Space between checkbox and label */
  }
  
  .combo-product-item input[type="checkbox"] {
    width: 18px;
    height: 18px;
    accent-color: #007bff; /* Color for the checkbox when checked */
    cursor: pointer;
  }
  
  .combo-product-item label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    cursor: pointer; /* Makes the label clickable */
  }
  
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  @media screen and (max-width: 768px) {
    .forms-container {
      flex-direction: column;
      gap: 20px;
    }
  }
  