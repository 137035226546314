.checkout-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.checkout-main {
  position: relative;
}

.with-sidebar {
  display: flex;
}

.with-sidebar .checkout-main > div {
  position: sticky;
  top: 0;
  z-index: 1;  /* Ensure it's above other elements if necessary */
}

.order-summary-sidebar {
  flex: 0 0 30%;
  padding: 16px;
  background: #f9f9f9;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 16px;
  height: fit-content;
}

.mobile-summary {
  margin-top: 20px;
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
}

.icon-btn:hover {
  background-color: #0056b3;
}

.icon-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.sidebar-navigation {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 buttons per row */
  gap: 16px; /* Spacing between buttons */
  margin-top: 16px;
}


/* Responsive Design */
@media (max-width: 768px) {
  .sidebar-navigation {
    grid-template-columns: 1fr; /* Stacks buttons on smaller screens */
    gap: 8px;
  }
}
