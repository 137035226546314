.admin-notifications-container {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .notifications-header {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .create-notification-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 auto 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .create-notification-btn:hover {
    background-color: #0056b3;
  }
  
  .notification-form-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .notification-form-container h3 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .form-row {
    margin-bottom: 15px;
  }
  
  .form-row label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  textarea,
  input[type="text"],
  input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .notifications-list {
    margin-top: 20px;
  }
  
  .notifications-header-row,
  .notifications-item {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .notifications-item img {
    max-width: 80px;
    max-height: 50px;
    border-radius: 5px;
  }
  
  .notifications-item .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .send-notification-btn {
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-notification-btn:hover {
    background-color: #218838;
  }
  