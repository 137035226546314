.admin-transaction-container {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}

.total-balance {
  text-align: right;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters input,
.filters select,
.filters button {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
}

.filters button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 0.5;
}

.filters button:hover {
  background-color: #0056b3;
}

.add-transaction-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.add-transaction-form select,
.add-transaction-form input,
.add-transaction-form button {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-transaction-form button {
  background-color: #28a745;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-transaction-form button:hover {
  background-color: #218838;
}

.transactions-table {
  border-top: 1px solid #ddd;
  margin-top: 20px;
}

.transactions-header {
  display: flex;
  font-weight: bold;
  background-color: #f9f9f9;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.transactions-header p {
  flex: 1;
  margin: 0;
  text-align: left;
}

.transaction-item {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.transaction-item p {
  flex: 1;
  margin: 0;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .filters {
      flex-direction: column;
  }

  .add-transaction-form {
      flex-direction: column;
  }

  .transactions-header,
  .transaction-item {
      flex-direction: column;
      text-align: left;
  }

  .filters input,
  .filters select,
  .filters button,
  .add-transaction-form select,
  .add-transaction-form input,
  .add-transaction-form button {
      flex: unset;
      width: 100%;
  }

  .transaction-item p {
      margin-bottom: 5px;
  }
}

@media screen and (max-width: 480px) {
  .total-balance {
      font-size: 16px;
      text-align: center;
  }

  .transactions-header,
  .transaction-item {
      padding: 8px;
  }

  .add-transaction-form {
      gap: 8px;
  }

  .filters input,
  .filters select,
  .filters button {
      font-size: 12px;
      padding: 8px;
  }
}
