.admin-dashboard-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.admin-dashboard-header h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.admin-dashboard-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* 3 columns per row */
  gap: 20px;
}

.voucher-stats {
  text-align: left;
  margin-top: 10px;
}

.voucher-stats p {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.admin-dashboard-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.admin-dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.admin-dashboard-icon {
  font-size: 40px;
  color: #4caf50;
  margin-bottom: 10px;
}

.admin-dashboard-card h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.admin-dashboard-count {
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #555;
}
