.checkout-widget {
    position: fixed;
    bottom: 50px; /* Adjust to fit above mobile navigation */
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: none;
    box-shadow: none;
    pointer-events: auto;
  }
  
  .widget-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    background: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 0.2s ease, color 0.2s ease;
  }
  
  .widget-btn span {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .widget-btn:hover {
    color: #007bff; /* Accent color for hover effect */
    transform: scale(1.1);
  }
  
  .widget-btn:active {
    transform: scale(1.05);
  }
  
  .prev-btn svg,
  .next-btn svg,
  .submit-btn svg {
    color: #000;
    transition: color 0.2s ease;
  }
  
  .widget-btn:hover svg {
    color: #333;
  }
  
  .prev-btn {
    justify-content: flex-start;
  }
  
  .next-btn,
  .submit-btn {
    justify-content: flex-end;
  }
  