/* Main Container */
.order-history-container {
    font-family: 'Poppins', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
  }
  
  /* Filter Section */
  .filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filter-select {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    font-size: 1rem;
  }
  
  /* Orders Grid */
  .orders-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Order Card */
  .order-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Order Summary */
  .order-summary h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .order-summary p {
    margin: 0;
    font-size: 0.9rem;
    color: #555;
  }
  
  /* Order Actions */
  .order-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .action-btn {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 0.9rem;
  }
  
  .reorder {
    background-color: #28a745;
    color: white;
  }
  
  .review {
    background-color: #ffc107;
    color: white;
  }
  
  .details {
    background-color: #007bff;
    color: white;
  }
  
  /* Button Hover Effects */
  .reorder:hover {
    background-color: #218838;
  }
  
  .review:hover {
    background-color: #e0a800;
  }
  
  .details:hover {
    background-color: #0056b3;
  }
  
  /* Order Details */
  .order-details {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    font-size: 0.9rem;
  }
  
  .order-details ul {
    padding-left: 20px;
    margin: 0;
  }
  
  .order-details li {
    margin-bottom: 5px;
  }
  