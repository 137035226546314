/* General Container */
.Admin-Notification-Container {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.Admin-Notification-Title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Create Button */
.Admin-Notification-Create-Button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 auto;
}

.Admin-Notification-Create-Button:hover {
  background-color: #0056b3;
}

/* No Data Message */
.Admin-Notification-No-Data {
  text-align: center;
  font-size: 16px;
  color: #777;
  margin-top: 20px;
}

/* Announcement Form */
.Admin-Notification-Form {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.Admin-Notification-Form h3 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.form-row-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  flex-direction: column;
}

.form-row label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.form-row input,
.form-row textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
}

.form-row input[type='file'] {
  padding: 5px;
}

textarea {
  height: 80px;
}

.form-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.form-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-actions button:first-of-type {
  background-color: #007bff;
  color: white;
}

.form-actions button:first-of-type:hover {
  background-color: #0056b3;
}

.form-actions button:last-of-type {
  background-color: #dc3545;
  color: white;
}

.form-actions button:last-of-type:hover {
  background-color: #c82333;
}

/* List of Announcements */
.Admin-Notification-List {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

/* Header Row */
.Admin-Notification-Header {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr 1fr 1.5fr;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.Admin-Notification-Header span {
  color: #333;
}

/* Each Announcement Item */
.Admin-Notification-Item {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr 1fr 1.5fr;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  align-items: center;
  gap: 10px;
  transition: box-shadow 0.3s ease;
}

.Admin-Notification-Item:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.Admin-Notification-Item img {
  width: 80px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* Actions for Each Announcement */
.Admin-Notification-Item-Actions {
  display: flex;
  gap: 10px;
}

.Admin-Notification-Item-Actions button {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.Admin-Notification-Item-Actions button:first-of-type {
  background-color: #28a745;
  color: white;
}

.Admin-Notification-Item-Actions button:first-of-type:hover {
  background-color: #218838;
}

.Admin-Notification-Item-Actions button:nth-of-type(2) {
  background-color: #17a2b8;
  color: white;
}

.Admin-Notification-Item-Actions button:nth-of-type(2):hover {
  background-color: #117a8b;
}

.Admin-Notification-Item-Actions button:last-of-type {
  background-color: #dc3545;
  color: white;
}

.Admin-Notification-Item-Actions button:last-of-type:hover {
  background-color: #c82333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-row-container {
    grid-template-columns: 1fr;
  }

  .Admin-Notification-Header {
    display: none; /* Hide header on small screens */
  }

  .Admin-Notification-Item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .Admin-Notification-Item img {
    width: 100%;
    height: auto;
  }

  .Admin-Notification-Item-Actions {
    flex-wrap: wrap;
    gap: 5px;
  }
}

/* Preview Modal */
.Admin-Notification-Preview-Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Admin-Notification-Preview-Content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 90%;
  text-align: center;
  height: 90%;
}

.Admin-Notification-Preview {
  margin-top: 20px;
  padding: 20px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  color: #333;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.Admin-Notification-Preview img {
  width: 60%; /* Reduce the image size for better balance */
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.Admin-Notification-Preview h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.Admin-Notification-Preview p {
  font-size: 16px;
  margin: 0;
}

.Admin-Notification-Preview-Content button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Admin-Notification-Preview-Content button:hover {
  background-color: #a71d2a;
}
