/* index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  background-color: #fff; /* Optional background for the app */
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

ul, ol {
  list-style: none;
}
