/* General container styling */
.review-page-container {
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    background-color: #f8f9fa;
  }
  
  /* Header styling */
  .review-header {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }

  .review-page-container h4 {
    font-size: 1.2rem;
    margin: 0;
    padding: 10px;
    max-width: 1000px;  /* Optional: limits the width of the h4 */
    width: 100%;  /* Ensures it takes up the full width but is constrained by max-width */
  }
  
  /* Optional media query for larger screens */
  @media screen and (min-width: 1200px) {
    .review-page-container {
      max-width: 1000px;  /* Limits container width on very large screens */
      margin: 0 auto;  /* Centers the container horizontally */
    }
  }
  
  /* Add review section */
  .add-review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-selector {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .review-input {
    width: 100%;
    max-width: 600px;
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 15px;
    resize: none;
  }
  
  .rating-container {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
  }
  
  .star-icon {
    font-size: 1.5rem;
    color: #ddd;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .star-icon.active {
    color: #ffc107;
  }
  
  .Home-submit-review-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .home-submit-review-btn:hover {
    background-color: #0056b3;
  }
  
  /* Reviews grid */
  .reviews-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }

  @media (min-width: 1024px) {
    .reviews-grid {
      grid-template-columns: repeat(4, 1fr); /* 4 columns per row */
    }
  }
  
  
  .review-card {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .review-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .review-text-section {
    flex: 1;
  }

  .review-image-section {
    width: 100%;
    max-width: 100px; /* Optional: Adjust the maximum width of the image */
    margin: 10px auto; /* Center-align the image */
    display: block;
  }
  
  .review-image {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Consistent rounding */
    object-fit: cover; /* Maintain aspect ratio */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for better aesthetics */
  }
  

  
.review-product {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
}
  
.review-text {
  margin-bottom: 10px; /* Add spacing below the comment */
}

.review-rating {
  margin-top: 10px; /* Add spacing above the star rating */
}
  
  .review-actions {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .home-like-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    background: none;
    border: none;
    font-size: 1rem;
    color: #dc3545;
    cursor: pointer;
  }
  

  
  .home-like-icon {
    font-size: 1.2rem;
  }
  
  @media screen and (max-width: 768px) {
    .review-image-section {
      max-width: 40%; /* Ensure it adjusts well on smaller screens */
    }
  }