/* General Container Styles */
.admin-chat-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Chat Icon (Floating Button) */
  .chat-icon {
    background-color: #3498db;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .chat-icon:hover {
    background-color: #1d7bb6;
    transform: scale(1.1);
  }
  
  /* Chat Container */
  .chat-container {
    width: 400px;
    height: 600px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: slideInUp 0.3s ease;
  }
  
  /* Chat Header */
  .chat-header {
    background: #3498db;
    color: white;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    border-bottom: 3px solid #2980b9;
  }
  
  .chat-header h3 {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .chat-header button.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .chat-header button.close-btn:hover {
    transform: rotate(90deg);
  }
  
  /* Chat Body */
  .chat-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
    background: #f7f9fc;
  }
  
  /* User List Section */
  .user-list {
    margin-bottom: 15px;
  }
  
  .user-list h4 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .user-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .user-list li {
    padding: 10px 15px;
    margin-bottom: 5px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s;
  }
  
  .user-list li:hover {
    background: #f1f8ff;
    border-color: #3498db;
  }
  
  .user-list li svg {
    color: #3498db;
  }
  
  /* Chat Tabs */
  .chat-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .chat-tab {
    border-radius: 8px;
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .chat-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .chat-tab-header h5 {
    font-size: 1rem;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .chat-tab-header button {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #e74c3c;
    cursor: pointer;
  }
  
  .chat-tab-header button:hover {
    color: #c0392b;
  }
  
  /* Chat Messages */
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 8px;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 0.9rem;
    max-width: 70%;
    word-wrap: break-word;
  }
  
  .message.user {
    background: #ecf0f1;
    align-self: flex-start;
    text-align: left;
  }
  
  .message.admin {
    background: #3498db;
    color: white;
    align-self: flex-end;
    text-align: right;
  }
  
  /* Chat Input */
  .chat-input {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    border-top: 1px solid #ddd;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .chat-input input:focus {
    border-color: #3498db;
  }
  
  .chat-input button {
    background: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .chat-input button:hover {
    background: #1d7bb6;
  }
  
  /* Animations */
  @keyframes slideInUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  