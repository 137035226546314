.developer-footer {
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: white;
    text-align: center;
    padding: 30px 20px; /* Increased padding for better spacing */
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px; /* Increased gap between text and icons */
    position: relative;
    bottom: 0;
    width: 100%;
    max-width: 1000px; /* Limits the width to center the footer */
    margin: 0 auto; /* Centers the footer */
}

/* Footer text styling */
.footer-text {
    margin: 0;
    font-size: 1.1rem; /* Slightly larger text */
}

/* Icon container */
.footer-icons {
    display: flex;
    gap: 25px; /* Space between icons */
    justify-content: center;
    margin-top: 10px; /* Adds spacing above the icons */
}

/* Footer icon styling */
.footer-icon {
    font-size: 1.8rem; /* Larger icons for better visibility */
    color: white;
    transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover effects for icons */
.footer-icon:hover {
    transform: scale(1.2); /* Zoom effect */
    color: #ff5733; /* Theme color on hover */
}

/* Icon-specific colors */
.whatsapp {
    color: #25d366; /* WhatsApp green */
}

.envelope {
    color: #d44638; /* Email red */
}

.twitter {
    color: #1da1f2; /* Twitter blue */
}

/* Ensure proper responsive behavior */
@media screen and (max-width: 768px) {
    .developer-footer {
        padding: 20px 15px; /* Adjust padding for small screens */
    }

    .footer-text {
        font-size: 1rem; /* Slightly smaller text on mobile */
    }

    .footer-icons {
        gap: 15px; /* Adjust gap for smaller screens */
    }

    .footer-icon {
        font-size: 1.5rem; /* Smaller icons on mobile */
    }
}

