.admin-create-account-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .admin-input-group {
    margin-bottom: 15px;
  }
  
  .admin-input-group input,
  .admin-input-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .admin-create-btn {
    width: 100%;
    padding: 10px;
    background: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-create-btn:disabled {
    background: #ccc;
  }
  