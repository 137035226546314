/* Main Container */
.track-order-container {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
  }
  
  /* Header */
  h1 {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Help Icon */
  .help-icon {
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 1.9rem;
    color: #007bff;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .help-icon:hover {
    transform: scale(1.2);
  }
  
  /* Modal */
  .help-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
  }
  
  .close-modal-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .close-modal-btn:hover {
    background-color: #0056b3;
  }
  

  

  
  .track-input-container input:focus {
    border-color: #007bff;
  }
  
  .track-input-container button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .track-input-container button:hover {
    background-color: #0056b3;
  }
  
  /* Spinner */
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  /* Order Status */
  .order-status-container {
    margin-top: 20px;
  }
  
  .status-section {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .status-section.completed {
    background-color: #e6ffe6;
    border-color: #28a745;
  }
  
  .status-icon {
    font-size: 1.8rem;
    color: #007bff;
    margin-right: 20px;
  }
  
  .status-completed {
    margin-left: auto;
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
  }
  